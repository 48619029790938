



























































































// Vue
import Vue from "vue";
// Services
import { mapActions, mapGetters } from "vuex";
// Models
import { IUser } from "@/core/_models/user.model";
// Components
import CreateUserDialog from "@/views/admin/_dialogs/users/CreateUserDialog.vue";
import UpdateUserDialog from "@/views/admin/_dialogs/users/UpdateUserDialog.vue";
// Drag n Drop
import draggable from "vuedraggable";
import AdminService from "./Admin.service";

export default Vue.extend({
  name: "Users",
  components: { draggable, CreateUserDialog, UpdateUserDialog },

  data() {
    return {
      search: "",
      isSearching: false,

      usersData: [] as IUser[]
    };
  },

  beforeMount() {
    this.getAllUsers();
  },

  methods: {
    ...mapActions("users", {
      fetchUsers: "fetchUsers",
      pushUsers: "pushUsers",
      setUser: "setUser",
      removeUser: "removeUser"
    }),

    getAllUsers(): void {
      AdminService.getAllUsers(this.currentUser.clientId).then((data: IUser[]) => {
        this.usersData = data;
      });
    },

    resetSearch(): void {
      this.search = "";
      this.isSearching = false;
    }
  },

  computed: {
    ...mapGetters({
      items: "users/items",
      currentUser: "currentUser"
    }),
    /**
     * Filters Quantities via search bar
     * @author Nick Brahimir
     */
    filteredUsers(): any {
      if (!this.usersData) return;
      return this.usersData.filter((element: IUser): any => {
        const fullName = element.FirstName + " " + element.LastName;
        return fullName.toLowerCase().includes(this.search.toLowerCase());
      });
    },

    /**
     * Animations for Drag n Drop (see docs below)
     * https://www.npmjs.com/package/vuedraggable
     * https://sortablejs.github.io/Vue.Draggable/#/transition-example-2
     * https://github.com/SortableJS/Vue.Draggable/blob/master/example/components/transition-example-2.vue
     * @author Nick Brahimir
     */
    dragOptions(): any {
      return {
        animation: 200,
        disabled: true, // Disabling DnD for Users.
        ghostClass: "ghost" // Corresponds to the scoped scss class.
      };
    },

    dragOff(): any {
      return {
        disabled: true
      };
    }
  }
});
